exports.components = {
  "component---src-pages-404-index-tsx": () => import("./../../../src/pages/404/index.tsx" /* webpackChunkName: "component---src-pages-404-index-tsx" */),
  "component---src-pages-company-index-tsx": () => import("./../../../src/pages/company/index.tsx" /* webpackChunkName: "component---src-pages-company-index-tsx" */),
  "component---src-pages-contact-index-tsx": () => import("./../../../src/pages/contact/index.tsx" /* webpackChunkName: "component---src-pages-contact-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-news-microcms-news-news-id-tsx": () => import("./../../../src/pages/news/{microcmsNews.newsId}.tsx" /* webpackChunkName: "component---src-pages-news-microcms-news-news-id-tsx" */),
  "component---src-pages-privacy-policy-index-tsx": () => import("./../../../src/pages/privacyPolicy/index.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-index-tsx" */),
  "component---src-pages-projects-microcms-projects-projects-id-tsx": () => import("./../../../src/pages/projects/{microcmsProjects.projectsId}.tsx" /* webpackChunkName: "component---src-pages-projects-microcms-projects-projects-id-tsx" */),
  "component---src-pages-service-index-tsx": () => import("./../../../src/pages/service/index.tsx" /* webpackChunkName: "component---src-pages-service-index-tsx" */),
  "component---src-pages-simulation-index-tsx": () => import("./../../../src/pages/simulation/index.tsx" /* webpackChunkName: "component---src-pages-simulation-index-tsx" */),
  "component---src-templates-news-index-tsx": () => import("./../../../src/templates/news/index.tsx" /* webpackChunkName: "component---src-templates-news-index-tsx" */)
}

